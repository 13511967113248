<template>
  <div class="pa-3 d-flex flex-column align-center _bg-default">
    <v-card pa-2 class="game">
      <v-toolbar color="indigo" class="mb-3" dense dark
        ><h3>Wiksa</h3>
        <v-spacer></v-spacer>
        <v-btn dark @click="HintWiksa = true" text>Instruksi Game</v-btn>
      </v-toolbar>
      <v-col cols="12" md="12" v-if="fromRoute">
        <v-btn
          color="indigo"
          dark
          class="ml-3"
          v-if="!isPlayed"
          @click="sendText"
          >Mulai Game</v-btn
        >
      </v-col>
      <v-col cols="12" md="12" class="ml-3">
        <unity
          src="/Wiksa/Build/Wiksa.json"
          width="900"
          height="500"
          unityLoader="/Wiksa/Build/UnityLoader.js"
          ref="myInstance"
        ></unity>
      </v-col>
    </v-card>
    <HintWiksa v-bind:HintWiksa="HintWiksa" @false="closeDialog" />
  </div>
</template>

<script>
import Unity from "vue-unity-webgl";
import HintWiksa from "../../components/_base/Game/HintWiksa.vue";

export default {
  name: "Game",
  components: {
    Unity,
    HintWiksa
  },

  data() {
    return {
      fromRoute: localStorage.getItem("from"),
      isPlayed: false,
      poin: 200,
      id: localStorage.getItem("id"),
      HintWiksa: true,
      no_soal: localStorage.getItem("no_soal")
    };
  },
  mounted() {
    console.log(`${this.id},${this.no_soal}`);
  },
  methods: {
    sendText() {
      this.$refs.myInstance.message(
        "GameHandler",
        "StartGame",
        `${this.id},${this.no_soal},${this.fromRoute}`
      );
      console.log(`${this.id},${this.no_soal},${this.fromRoute}`);
      this.isPlayed = true;
    },
    closeDialog() {
      this.HintWiksa = false;
    }
  }
};
</script>

<style scoped>
._bg-default {
  background: #f4f6f9;
}
.game {
  width: max-content;
}
</style>
